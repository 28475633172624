.upload-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.upload-sidebar-bottom {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}
