.last-comments-general-comments {
  cursor: default !important;
}

.saved-review-sidebar-general-comments {
  outline: none;
  box-shadow: none !important;
  border-color: var(--primary-dark) !important;
  margin-top: 10px;
  cursor: default;
}

.general-comment-box-input {
  cursor: default !important;
}
