.reviews-header-title {
  color: var(--lighter-black);
}

/* sm */
.reviews-container {
  margin-top: 60px;
}

.reviews-header-wide {
  display: none;
}

.reviews-header-small {
  display: block;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
}

/* md */
@media screen and (min-width: 768px) {
  .reviews-container {
    margin-top: 30px;
  }

  .reviews-header-wide {
    display: block;
    margin: 0 0.35rem;
  }

  .reviews-header-small {
    display: none;
  }
}
