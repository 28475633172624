.my-grid-empty {
  padding: 4rem;
  text-align: center;
}

.my-grid .grid-item-share-button {
  color: var(--grey);
  cursor: pointer;
}

.my-grid .grid-item-share-button:hover {
  color: var(--secondary);
}

.my-grid .grid-item-delete-button {
  color: var(--grey);
  margin-left: 10px;
  cursor: pointer;
}

.my-grid .grid-item-delete-button:hover {
  color: var(--danger);
}

.my-grid .grid-item-delete-button-selected {
  color: var(--danger);
}

.my-grid .grid-item-fill-row {
  flex-grow: 1;
  display: inline;
}

.my-grid .flex-parent {
  display: flex;
}

.my-grid .text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
  display: block;
}

.grid-item-reviewer-name {
  margin-top: -3px;
  padding-right: 3px;
}

.grid-item img {
  pointer-events: none;
  position: absolute;
  width: 100%;
}

/* sm */
.my-grid-container {
  padding: 0;
}

.my-grid .item-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin-bottom: 2rem;
}

.grid-upload-button {
  width: 70vw;
  height: 90.59vw;
  box-shadow: 2px 4px 4px rgba(7, 57, 60, 0.25);
  border-radius: 15px;
}

.grid-upload-button svg {
  margin-bottom: 1rem;
}

.my-grid .grid-item-container {
  display: flex;
  flex-direction: column;
  box-shadow: 2px 4px 4px rgba(7, 57, 60, 0.25);
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.my-grid .grid-item-container-clickable:hover {
  outline: 4px solid var(--secondary-light);
}

.my-grid .grid-item-container-clickable:focus {
  outline: 4px solid var(--secondary-light);
}

.grid-item-container-clickable .grid-item-overlay {
  position: absolute;
  bottom: 0;
  box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: white;
  padding: 1rem;
  width: 100%;
  cursor: default;
  z-index: 1;
}

.my-grid .grid-item-overlay-info {
  align-items: flex-end;
}

.grid-item-container .grid-item {
  width: 70vw;
  height: 90.59vw;
}

/* md */
@media screen and (min-width: 768px) {
  .my-grid-container {
    padding: 0 1.5rem;
    margin: 0 0.5rem;
  }

  .my-grid .item-container {
    padding: 0.8rem 0.4rem;
    margin: 0;
  }

  .grid-upload-button {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    box-shadow: 2px 4px 4px rgba(7, 57, 60, 0.25);
    border-radius: 8px;
  }

  .my-grid .grid-item-container {
    border-radius: 8px;
    width: 100%;

    /* Make height match letter sized paper
    * 8.5 x 11 paper, here width = 100% so height is 11/8.5 = approx 129%
    * padding is applied to top and bottom, 129%/2 = approx 65% */
    box-sizing: border-box;
    padding: 65% 0;
  }

  .grid-item-container .grid-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
  }

  .grid-item-container-clickable .grid-item-overlay {
    padding: 0.7rem;
    z-index: 1;
  }
}

/* lg */
@media screen and (min-width: 992px) {
  .my-grid .item-container {
    padding: 0.8rem 0.6rem;
    margin: 0;
  }
}

/* xl */
@media screen and (min-width: 1200px) {
  .my-grid .item-container {
    padding: 0.8rem 0.6vw;
    margin: 0;
  }
}

/* xl */
@media screen and (min-width: 1500px) {
  .my-grid .item-container {
    padding: 0.8rem 1.5vw;
    margin: 0;
  }
}
