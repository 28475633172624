.rkmodal.modal-dialog {
  max-width: 45rem !important;
  width: 45rem;
}

.rkmodal .modal-content {
  border: 0;
  border-radius: 0.5em;
  overflow: hidden;
  background-color: var(--lightest-grey);
}

/* Bootstrap overrides */
.modal-backdrop {
  min-height: 100%;
  min-width: 100%;
}

/* Bootstrap enter/exit animation overrides */
.modal.fade {
  transition: opacity 0.4s linear;
}

.modal.fade .rkmodal.modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(-50vw, 0);
}

.modal.show .rkmodal.modal-dialog {
  transform: none;
}
