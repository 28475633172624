@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,500,700);
:root {
  --primary: #01d2b7; /* Primary accent colour */
  --primary-dark: #1abba6; /* Slightly darker primary, for stuff like hover */
  --secondary: #07383b; /* Secondary accent colour, for things like buttons */
  --secondary-light: #2b656d; /* Slightly lighter secondary, for stuff like hover */
  --tertiary: #acacac; /* Tertiary accent colour, for things like buttons */
  --tertiary-dark: #9b9b9b; /* Slightly darker tertiary, for stuff like hover */
  --danger: #ff7171; /* Danger colour */
  --danger-dark: #ec5252; /* Slightly darker danger, for stuff like hover */
  --lighter-black: #6d6d6d; /* A lighter black colour, for stuff like hover on black text */
  --lightest-black: #737373; /* Lightest black colour, for stuff like disabled black text */
  --grey: #acacac; /* For outlines of white elements */
  --light-grey: #e3e3e3; /* For placeholder element hover colours */
  --lighter-grey: #f0f0f0; /* For placeholder element colours */
  --lightest-grey: #f6f6f6; /* Grey for backgrounds, neutral alternate to white */
}

h1 {
  font-family: 'Roboto', sans-serif;
}

body {
  font-family: 'Open Sans', sans-serif;
  height: 100vh;
  background-color: #f6f6f6;
  background-color: var(--lightest-grey);
}

body .tooltip {
  font-family: 'Open Sans', sans-serif;
}

/* ----- Customize scrollbar ------ */

/* Only supported in Chrome, Safari, and Edge */
*::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #acacac;
  background-color: var(--grey);
  border-radius: 8px;
}

*::-webkit-scrollbar-button {
  display: none;
}

/* --------------------------------- */

/* ----- Customize Cursors ------ */
.pin-cursor {
  /* Only works on Safari and Chrome */
  cursor: -webkit-image-set(url(/static/media/pin-cursor.d983f97f.svg) 1x, url(/static/media/pin-cursor2.fd812deb.svg) 2x) 8 19, auto;
}

@-moz-document url-prefix() {
  .pin-cursor {
    cursor: url(/static/media/pin-cursor.d983f97f.svg) 8 19, auto;
  }
}

.draw-cursor {
  /* Only works on Safari and Chrome */
  cursor: -webkit-image-set(url(/static/media/draw-cursor.da311f62.svg) 1x, url(/static/media/draw-cursor2.3520fae9.svg) 2x) 2 18, auto;
}

@-moz-document url-prefix() {
  .draw-cursor {
    cursor: url(/static/media/draw-cursor.da311f62.svg) 0 18, auto;
  }
}

.erase-cursor {
  /* Only works on Safari and Chrome */
  cursor: -webkit-image-set(url(/static/media/erase-cursor.9a4ab1ae.svg) 1x, url(/static/media/erase-cursor2.5923c88e.svg) 2x) 5 16, auto;
}

@-moz-document url-prefix() {
  .erase-cursor {
    cursor: url(/static/media/erase-cursor.9a4ab1ae.svg) 5 16, auto;
  }
}

.crosshair-cursor {
  cursor: crosshair;
}

.no-pointer-events {
  pointer-events: none;
}

/* --------------------------------- */

/* dark green (primary coloured) button */
.primary-button {
  color: white;
  background-color: #01d2b7;
  background-color: var(--primary);
  border: none;
}

.primary-button:hover {
  background-color: #1abba6;
  background-color: var(--primary-dark);
}

.primary-button:focus {
  background-color: #1abba6;
  background-color: var(--primary-dark);
  box-shadow: none;
}

.primary-button:active {
  background-color: #1abba6 !important;
  background-color: var(--primary-dark) !important;
  box-shadow: none !important;
}

.primary-button:disabled {
  background-color: #01d2b7;
  background-color: var(--primary);
}

.primary-button:disabled:active {
  background-color: #01d2b7 !important;
  background-color: var(--primary) !important;
}

/* grey button */
.tertiary-button {
  color: white;
  background-color: #acacac;
  background-color: var(--tertiary);
  border: none;
}

.tertiary-button:hover {
  color: white;
  background-color: #9b9b9b;
  background-color: var(--tertiary-dark);
}

.tertiary-button:focus {
  color: white;
  background-color: #9b9b9b;
  background-color: var(--tertiary-dark);
  box-shadow: none;
}

.tertiary-button:active {
  color: white !important;
  background-color: #9b9b9b !important;
  background-color: var(--tertiary-dark) !important;
  box-shadow: none !important;
}

.tertiary-button:disabled {
  color: white;
  background-color: #acacac;
  background-color: var(--tertiary);
}

.tertiary-button:disabled:active {
  color: white !important;
  background-color: #acacac;
  background-color: var(--tertiary);
}

/* danger (red coloured) button */
.danger-button {
  color: white;
  background-color: #ff7171;
  background-color: var(--danger);
  border: none;
}

.danger-button:hover {
  background-color: #ec5252;
  background-color: var(--danger-dark);
}

.danger-button:focus {
  background-color: #ec5252;
  background-color: var(--danger-dark);
  box-shadow: none;
}

.danger-button:active {
  background-color: #ec5252 !important;
  background-color: var(--danger-dark) !important;
  box-shadow: none !important;
}

.danger-button:disabled {
  background-color: #ff7171;
  background-color: var(--danger);
}

.danger-button:disabled:active {
  background-color: #ff7171 !important;
  background-color: var(--danger) !important;
}

/* tool buttons */
.tool-button {
  background-color: transparent;
  color: black;
  height: 2.8rem;
  border-radius: 0;
  padding-top: 0;
  border: none;
  padding-bottom: 0.75rem !important;
}

.tool-button-selected {
  color: #1abba6;
  color: var(--primary-dark);
  height: 2.8rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.3rem solid #01d2b7;
  border-bottom: 0.3rem solid var(--primary);
  padding-bottom: 0.45rem !important;
}

.tool-button:hover {
  background-color: transparent;
  color: #6d6d6d;
  color: var(--lighter-black);
  border: none;
  padding-bottom: 0.75rem !important;
}

.tool-button:active {
  background-color: transparent !important;
  color: #6d6d6d !important;
  color: var(--lighter-black) !important;
  border: none !important;
  box-shadow: none !important;
  padding-bottom: 0.75rem !important;
}

.tool-button:focus {
  background-color: transparent;
  box-shadow: none;
  color: #6d6d6d;
  color: var(--lighter-black);
  height: 2.8rem;
  padding-bottom: 0.75rem !important;
}

.tool-button:disabled {
  background-color: transparent;
  color: #737373;
  color: var(--lightest-black);
  height: 2.8rem;
  border: none;
  padding-bottom: 0.75rem !important;
}

.tool-button-selected:hover {
  color: #1abba6 !important;
  color: var(--primary-dark) !important;
  height: 2.8rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.3rem solid #01d2b7 !important;
  border-bottom: 0.3rem solid var(--primary) !important;
  padding-bottom: 0.45rem !important;
}

.tool-button-selected:active {
  color: #1abba6;
  color: var(--primary-dark);
  height: 2.8rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.3rem solid #01d2b7;
  border-bottom: 0.3rem solid var(--primary);
  padding-bottom: 0.45rem !important;
}

.tool-button-selected:focus {
  color: #1abba6;
  color: var(--primary-dark);
  height: 2.8rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.3rem solid #01d2b7;
  border-bottom: 0.3rem solid var(--primary);
  padding-bottom: 0.45rem !important;
}

.tool-button-selected:disabled {
  background-color: transparent;
  color: #737373;
  color: var(--lightest-black);
  height: 2.8rem;
  border: none;
  padding-bottom: 0.75rem !important;
}

.tool-button-selected:disabled:hover {
  background-color: transparent !important;
  color: #737373 !important;
  color: var(--lightest-black) !important;
  border: none !important;
  padding-bottom: 0.75rem !important;
}

.loggedin-dropdown {
  margin-right: 1.2rem;
  margin-top: 5px;
}

.loggedin-dropdown-avatar {
  min-width: 1.8rem;
  max-width: 2.2rem;
  width: 2vw;
  border-radius: 50%;
  margin-right: 0.7rem;
}

.loggedin-dropdown-toggle {
  cursor: pointer;
}

.loggedin-dropdown-toggle:hover {
  color: var(--lighter-black);
}

.loggedin-dropdown-toggle:focus {
  color: var(--lighter-black);
  outline: none;
}

.loggedin-dropdown-toggle:active {
  color: var(--lighter-black);
}

.loggedin-dropdown-menu {
  margin-top: 5px !important;
  border: none !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.loggedin-dropdown-menu .loggedin-dropdown-menu-item:active {
  color: var(--lighter-black);
  background-color: white;
}

.loggedin-dropdown-menu .loggedin-dropdown-menu-item:hover {
  color: var(--lighter-black);
  background-color: white;
}

.loggedin-dropdown-menu .loggedin-dropdown-menu-item:focus {
  color: var(--lighter-black);
  background-color: white;
  outline: none;
}

.header-fill {
  flex-grow: 1;
}

.header-shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.header-title h1 {
  margin: 0;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2rem !important;
  color: black;
  margin-left: 0.5rem;
}

.header-logo {
  height: 100%;
  max-height: 30px;
  vertical-align: baseline;
  display: inline;
}

.header-user-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
}

/* sm */
.header {
  display: flex;
  padding-left: 0.6rem;
  width: 100%;
}

.header .navbar-brand {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}

.header-wrapper .navbar {
  background-color: white;
  padding-top: 0;
  padding-bottom: 0 !important;
  align-items: start !important;
}

/* md */
@media screen and (min-width: 768px) {
  .header {
    min-height: 44px;
    max-height: 53px;
    height: 3vw;
  }

  .header .navbar-brand {
    align-items: unset;
  }
}

.errorboundary {
  margin: 2rem 2.5rem 2rem 2.5rem;
}

.errorboundary button {
  margin: 0.5rem 0 0.5rem 0;
  background-color: var(--primary);
  border-color: transparent;
  color: white;
}

.errorboundary button:hover {
  background-color: var(--primary-dark);
  border-color: transparent;
  color: white;
}

.errorboundary button:focus {
  background-color: var(--primary-dark);
  border-color: transparent;
  color: white;
  box-shadow: none;
}

.errorboundary button:active {
  background-color: var(--primary) !important;
  border-color: transparent !important;
  color: white !important;
  box-shadow: none !important;
}

.rknavbutton {
  padding: 5px 10px;
  border-radius: 5px;
}

.rknavbutton-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.rknavbutton-content h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.rknavbutton-left svg {
  margin-right: 6px;
}

.rknavbutton-right svg {
  margin-left: 6px;
}

.my-grid-empty {
  padding: 4rem;
  text-align: center;
}

.my-grid .grid-item-share-button {
  color: var(--grey);
  cursor: pointer;
}

.my-grid .grid-item-share-button:hover {
  color: var(--secondary);
}

.my-grid .grid-item-delete-button {
  color: var(--grey);
  margin-left: 10px;
  cursor: pointer;
}

.my-grid .grid-item-delete-button:hover {
  color: var(--danger);
}

.my-grid .grid-item-delete-button-selected {
  color: var(--danger);
}

.my-grid .grid-item-fill-row {
  flex-grow: 1;
  display: inline;
}

.my-grid .flex-parent {
  display: flex;
}

.my-grid .text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
  display: block;
}

.grid-item-reviewer-name {
  margin-top: -3px;
  padding-right: 3px;
}

.grid-item img {
  pointer-events: none;
  position: absolute;
  width: 100%;
}

/* sm */
.my-grid-container {
  padding: 0;
}

.my-grid .item-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin-bottom: 2rem;
}

.grid-upload-button {
  width: 70vw;
  height: 90.59vw;
  box-shadow: 2px 4px 4px rgba(7, 57, 60, 0.25);
  border-radius: 15px;
}

.grid-upload-button svg {
  margin-bottom: 1rem;
}

.my-grid .grid-item-container {
  display: flex;
  flex-direction: column;
  box-shadow: 2px 4px 4px rgba(7, 57, 60, 0.25);
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.my-grid .grid-item-container-clickable:hover {
  outline: 4px solid var(--secondary-light);
}

.my-grid .grid-item-container-clickable:focus {
  outline: 4px solid var(--secondary-light);
}

.grid-item-container-clickable .grid-item-overlay {
  position: absolute;
  bottom: 0;
  box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: white;
  padding: 1rem;
  width: 100%;
  cursor: default;
  z-index: 1;
}

.my-grid .grid-item-overlay-info {
  align-items: flex-end;
}

.grid-item-container .grid-item {
  width: 70vw;
  height: 90.59vw;
}

/* md */
@media screen and (min-width: 768px) {
  .my-grid-container {
    padding: 0 1.5rem;
    margin: 0 0.5rem;
  }

  .my-grid .item-container {
    padding: 0.8rem 0.4rem;
    margin: 0;
  }

  .grid-upload-button {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    box-shadow: 2px 4px 4px rgba(7, 57, 60, 0.25);
    border-radius: 8px;
  }

  .my-grid .grid-item-container {
    border-radius: 8px;
    width: 100%;

    /* Make height match letter sized paper
    * 8.5 x 11 paper, here width = 100% so height is 11/8.5 = approx 129%
    * padding is applied to top and bottom, 129%/2 = approx 65% */
    box-sizing: border-box;
    padding: 65% 0;
  }

  .grid-item-container .grid-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
  }

  .grid-item-container-clickable .grid-item-overlay {
    padding: 0.7rem;
    z-index: 1;
  }
}

/* lg */
@media screen and (min-width: 992px) {
  .my-grid .item-container {
    padding: 0.8rem 0.6rem;
    margin: 0;
  }
}

/* xl */
@media screen and (min-width: 1200px) {
  .my-grid .item-container {
    padding: 0.8rem 0.6vw;
    margin: 0;
  }
}

/* xl */
@media screen and (min-width: 1500px) {
  .my-grid .item-container {
    padding: 0.8rem 1.5vw;
    margin: 0;
  }
}

.all-resumes-container {
  margin-top: 20px;
}

.all-resumes-header-title {
  color: var(--lighter-black);
  margin-left: 20px;
}

/* sm */
.all-resumes-header {
  display: flex;
  align-items: flex-end;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

/* md */
@media screen and (min-width: 768px) {
  .all-resumes-header {
    margin: 0 0.35rem;
  }
}

/* Vaguely adapted from https://moderncss.dev/pure-css-custom-checkbox-style */

.rkcheckbox-danger {
  color: var(--danger);
}

.rkcheckbox {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 0;
  font-size: 1rem;
}

.rkcheckbox-input-wrapper {
  width: 1.1em;
  height: 1.1em;
  min-width: 1.1em;
  min-height: 1.1em;
  box-shadow: 0 0 0 0.09em inset;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
}

.rkcheckbox-label {
  line-height: 1em;
}

/* Hide the actual input (can't be styled) while maintaining click events */
.rkcheckbox-input-wrapper input {
  visibility: hidden;
  cursor: pointer;
  opacity: 0;
  width: 1em;
  height: 1em;

  /* Controls the touch area of the input box */
  position: absolute;
  left: 0;
  top: 0;
}

.rkcheckbox-checked-icon {
  pointer-events: none;
  width: 0.6em;
  height: 0.6em;
  border-radius: 0.15em;
  background-color: currentColor;
  transition: opacity 0.15s;
  opacity: 0;
}

.rkcheckbox-input-wrapper input:hover + .rkcheckbox-checked-icon {
  opacity: 0.25;
}

.rkcheckbox-input-wrapper input:checked + .rkcheckbox-checked-icon {
  opacity: 1;
}

.rkcontexttext {
  color: var(--grey);
  display: inline-block;
}

.rkemphasistext {
  color: var(--primary-dark);
  display: inline-block;
}

.rkhelptooltip .tooltip {
  opacity: 1;
}

.rkhelptooltip {
  transition: opacity 0.1s linear;
}

.rkhelptooltip .tooltip-inner {
  background-color: var(--secondary);
  border-radius: 3;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}

.rkhelptooltip .arrow::before {
  border-bottom-color: var(--secondary) !important;
}

@media (hover: none) {
  /*  Stop tooltip from showing on devices without hover (ex: tables)
   *  https://stackoverflow.com/a/54556732
   */
  .rkhelptooltip .tooltip {
    display: none;
  }
}

.delete-popover button {
  display: flex;
  flex-grow: 1;
  margin-left: auto;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.delete-popover .popover {
  border-radius: 5px;
  border: 2px solid var(--danger);
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 8px;
}

.delete-popover .arrow::before {
  border-bottom-color: var(--danger) !important;
  border-top-color: var(--danger) !important;
}

.delete-popover .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 3px;
}

.delete-popover .bs-popover-auto[x-placement^="top"] > .arrow::after {
  top: -3px;
}

.delete-popover .rkcontexttext {
  cursor: default;
}

@-webkit-keyframes error-toast-entering {
  from {
    bottom: -4rem;
  }

  to {
    bottom: 1rem;
  }
}

@keyframes error-toast-entering {
  from {
    bottom: -4rem;
  }

  to {
    bottom: 1rem;
  }
}

@-webkit-keyframes error-toast-exiting {
  from {
    bottom: 1rem;
  }

  to {
    bottom: -4rem;
  }
}

@keyframes error-toast-exiting {
  from {
    bottom: 1rem;
  }

  to {
    bottom: -4rem;
  }
}

.error-toast-icon-wrapper {
  margin-right: 1em;
  border: 0.25em solid white;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-toast {
  background-color: var(--danger);
  color: white;
  padding: 1em;
  border-radius: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  /* Position-related */
  position: fixed;
  right: 1rem;
  z-index: 100;
}

.error-toast-entering {
  bottom: 1rem;
  -webkit-animation-name: error-toast-entering;
          animation-name: error-toast-entering;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: cubic-bezier(0.38, 1.4, 0.64, 1);
          animation-timing-function: cubic-bezier(0.38, 1.4, 0.64, 1);
}

.error-toast-exiting {
  bottom: -4rem;
  -webkit-animation-name: error-toast-exiting;
          animation-name: error-toast-exiting;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}

.error-toast-hidden {
  display: none;
}

/* sm */
.homeview {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 274px;
}

.homeview .header-title h1 {
  display: none;
}

.homeview .login-loggedin-avatar {
  width: 2.5rem;
}

.homeview .login-loggedin-name {
  display: none;
}

.homeview .login-loggedin {
  margin-right: 0;
  padding-right: 0;
}

.homeview-content {
  overflow-y: scroll;
  padding-bottom: 30px;
}

/* md */
@media screen and (min-width: 768px) {
  .homeview .header-title h1 {
    display: inline;
  }

  .homeview .login-loggedin-avatar {
    width: 2.5rem;
  }

  .homeview .login-loggedin-name {
    display: inline;
  }

  .homeview .login-loggedin {
    margin-right: 1.2rem;
    padding-right: 0.6rem;
  }

  .homeview-content {
    padding-bottom: 40px;
  }
}

/* lg */
@media screen and (min-width: 992px) {
  .pdftoolbar .tool-button-text {
    display: inline;
    font-size: 1.2vw;
  }
}

/* xl */
@media screen and (min-width: 1200px) {
  .pdftoolbar .tool-button-text {
    font-size: 1vw;
  }
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.recent-uploads-container {
  margin-top: 20px;
}

.recent-uploads-header-title {
  flex-grow: 1;
  color: var(--lighter-black);
}

/* sm */
.recent-uploads-header-small {
  display: block;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 30px;
}

.recent-uploads-header-wide {
  display: none;
}

/* md */
@media screen and (min-width: 768px) {
  .recent-uploads-header-small {
    display: none;
  }

  .recent-uploads-header-wide {
    display: flex;
    align-items: center;
    margin: 0 0.35rem;
    height: 35px;
  }
}

.upload-outline {
  border-radius: 5px;
  outline: 4px solid var(--secondary-light);
}

.rkpill {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1;
  margin: 10px;
  border-radius: 50px;
  padding: 0 10px;
  pointer-events: none;

  /* Default colours */
  color: white;
  background-color: var(--primary);
}

.rkpill-danger {
  color: white;
  background-color: var(--danger);
}

.rkpill-topright {
  position: absolute;
  top: 0;
  right: 0;
}

.reviews-header-title {
  color: var(--lighter-black);
}

/* sm */
.reviews-container {
  margin-top: 60px;
}

.reviews-header-wide {
  display: none;
}

.reviews-header-small {
  display: block;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
}

/* md */
@media screen and (min-width: 768px) {
  .reviews-container {
    margin-top: 30px;
  }

  .reviews-header-wide {
    display: block;
    margin: 0 0.35rem;
  }

  .reviews-header-small {
    display: none;
  }
}

.google-login-button svg {
  margin-bottom: 0.28rem;
}

.linkedIn-button svg {
  color: rgb(0, 114, 177);
  margin-right: 14px;
  margin-bottom: -0.2rem !important;
  width: 25px !important;
  height: 25px !important;
}

.login-container {
  align-items: center;
  margin-top: 1.5rem;
  width: 100%;
}

.login-container button {
  justify-content: center;
  font-size: 1.1rem !important;
  margin-bottom: 0.5rem;
  width: 280px;
  height: 60px;
  border-radius: 7px !important;
  border: 1px solid lightgrey !important;
  box-shadow: rgb(0 0 0 / 24%) 0 2px 2px 0, rgb(0 0 0 / 24%) 0 0 1px 0;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.54);
}

.login-container .alert {
  margin-bottom: 0.7rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 40rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.login-container button:hover {
  opacity: 0.9;
}

.login-page {
  background-image: url(/static/media/login-bg.55d873cd.png);
  height: 100vh;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-width: 500px;
}

.login-page .navbar {
  background-color: transparent;
}

.login-page-modal-body {
  justify-content: center;
  text-align: center;
  object-fit: contain;
}

/* sm */
.login-page-modal {
  background-color: rgba(250, 250, 250, 0.85);
  width: 80%;
  border-radius: 9px;
  border-color: #f1f1f1;
  margin: auto;
  padding-top: 70px;
  padding-bottom: 100px;
  padding-left: 60px;
  padding-right: 60px;
}

.login-page-modal img {
  display: none;
}

.login-page-modal h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.login-page-modal h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  margin-bottom: 0.7rem;
}

/* md */
@media screen and (min-width: 768px) {
  .login-page-modal {
    width: 740px;
    height: 75vh;
    padding-top: 30px;
    padding-bottom: 0;
    padding-left: 60px;
    padding-right: 60px;
  }

  .login-page-modal img {
    display: inline-block;
    height: 40vh;
  }

  .login-page-modal h3 {
    margin-bottom: 2rem;
  }
}

/* lg */
@media screen and (min-width: 992px) {
  .login-page-modal {
    width: 770px;
    height: 80vh;
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 60px;
  }

  .login-page-modal img {
    height: 45vh;
  }

  .login-page-modal h3 {
    margin-bottom: 2.5rem;
  }
}

.notfound {
  margin: 2rem 2.5rem 2rem 2.5rem;
}

.notfound button {
  margin: 0.5rem 0 0.5rem 0;
  background-color: var(--primary);
  border-color: transparent;
  color: white;
}

.notfound button:hover {
  background-color: var(--primary-dark);
  border-color: transparent;
  color: white;
}

.notfound button:focus {
  background-color: var(--primary-dark);
  border-color: transparent;
  color: white;
  box-shadow: none;
}

.notfound button:active {
  background-color: var(--primary) !important;
  border-color: transparent !important;
  color: white !important;
  box-shadow: none !important;
}

.rkcard {
  background-color: white;
  border-radius: 5px;
  box-shadow: -4px 4px 4px 0 rgba(0, 0, 0, 0.1);
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 23px;
  padding-right: 23px;
}

.rkcard-default-margins {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
}

.rkcard-no-top-margins {
  margin-bottom: 10px;
  margin-right: 5px;
}

.rkcard-center {
  text-align: center;
}

.scroll-scrollable {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow-y: auto;
}

.scroll-content {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
}

.scroll-overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  z-index: 1;
  top: 0;
  pointer-events: none;
}

.scroll-overlay::before,
.scroll-overlay::after {
  content: '';
  height: 40px;
  position: absolute;
}

.scroll-overlay::before {
  background: linear-gradient(white, rgba(255, 255, 255, 0));
  top: 0;
  width: var(--widthTop);
}

.scroll-overlay::after {
  background: linear-gradient(rgba(255, 255, 255, 0), white);
  bottom: 0;
  width: var(--widthBottom);
}

.last-comments-general-comments {
  cursor: default !important;
}

.saved-review-sidebar-general-comments {
  outline: none;
  box-shadow: none !important;
  border-color: var(--primary-dark) !important;
  margin-top: 10px;
  cursor: default;
}

.general-comment-box-input {
  cursor: default !important;
}

.comment-box-input {
  margin: 0;
  padding: 0.3rem 0.3rem 0.1rem 0.3rem !important;
  border: none !important;
  flex-grow: 1;
  resize: none;
  width: 100%;
  outline: none;
  overflow: hidden;
}

.comment-box-input textarea {
  overflow: hidden;
}

.comment-box-input:focus {
  box-shadow: none !important;
}

.comment-box-input:disabled {
  background-color: white;
  cursor: pointer;
  color: black;
}

.last-comments {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%; /* Necessary for Safari to display comments */
}

.last-comments-header {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 1.4em;
  font-weight: 500;
}

.last-comments-header-disabled {
  color: var(--lightest-black);
}

.commentmodal-button {
  color: var(--tertiary);
  position: absolute;
  top: 0.2em;
  right: 0.3em;
}

.commentmodal-button:hover {
  color: var(--tertiary-dark);
}

.last-comments-comment-block-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.4em 0.2em 0.3em;
}

.last-comments-pin {
  font-size: 1.65em;
  color: var(--primary);
  top: -0.38em;
  left: -0.28em;
  position: absolute;
}

.last-comments-pin-enabled {
  color: var(--primary);
}

.last-comments-pin-disabled {
  color: var(--tertiary);
}

.last-comments-pin-deselected {
  opacity: 0.7;
}

.last-comments-pin-backing {
  color: white;
}

.last-comments-pin-comment {
  margin-bottom: 0.5em;
  flex-wrap: nowrap;
  cursor: pointer;
}

.last-comments-pin-outline path {
  stroke: var(--secondary);
  stroke-width: 120px;
}

.last-comments-pin-comment:hover .last-comments-pin-deselected {
  opacity: 1;
}

.last-comments-footer-text {
  margin-left: 0.3rem;
}

.last-comments-comment {
  text-align: left;
  font-size: 0.9em;
  padding: 0.5em 1em 0 1em;
}

.last-comments-comment-collapse {
  padding: 0 1em 0 1em;
  max-height: 2.5em;
}

.last-comments-comment-dismissed {
  overflow: hidden;
}

.last-comments-comment-dismissed .comment-box-input:disabled {
  color: var(--tertiary-dark);
}

.last-comments-comment-block {
  background-color: white;
  flex-grow: 1;
  flex-direction: row;
  margin-top: 0.4em;
  margin-left: 0.4rem;
  border: 1px solid var(--grey);
  border-radius: 0.3em;
  box-shadow: 2px 4px 4px rgba(7, 57, 60, 0.25);
  position: relative;
}

.notes-displayer .form-control:disabled {
  height: 4rem;
  box-shadow: none;
  border-color: var(--grey);
  font-size: small;
  resize: none;
  background-color: white;

  /* Required for Safari */
  -webkit-text-fill-color: black;
}

.notes-text-displayer {
  margin: 0;
  padding: 0.375rem 0.75rem;
  flex-grow: 1;
  resize: none;
  width: 100%;
  border-color: var(--grey);
  overflow: hidden;
  background-color: white;
  font-size: small;
  overflow-y: scroll;

  /* Rounded corners */
  border-radius: 5px;
}

.review-empty-base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.review-empty-resume-block {
  background-color: var(--lighter-grey);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 65vh;
  height: 100%;
  text-align: center;
}

.review-empty-resume-block img {
  max-width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  pointer-events: none;
}

.review-empty-resume-block h5 {
  cursor: default;
  color: var(--lighter-black);
  font-size: 2.5vh;
}

.review-empty-resume-block .rkemphasistext {
  cursor: default;
  font-size: 3vh;
}

.rkheaderbutton {
  padding: 0.4rem 0.8rem;
  background-color: transparent;
  border: 0;

  /* This line fixes flickering issues with the bottom border on transition, but I have no idea why */
  border-top: 0.1px solid transparent;
  border-bottom: 5px solid transparent;
  transition: color 0.15s, border-bottom-color 0.1s;
}

.rkheaderbutton:disabled {
  pointer-events: none;
  color: var(--lightest-black);
  border-bottom-color: transparent;
}

.rkheaderbutton-deselected {
  color: black;
  border-bottom-color: transparent;
}

.rkheaderbutton-deselected:hover {
  color: var(--lighter-black);
  border-bottom-color: transparent;
}

.rkheaderbutton-deselected:active {
  color: var(--primary);
  border-bottom-color: var(--primary);
}

.rkheaderbutton-selected {
  color: var(--primary);
  border-bottom-color: var(--primary);
}

.rkheaderbutton-selected-deselectable {
  color: var(--primary);
  border-bottom-color: var(--primary);
}

.rkheaderbutton-selected-deselectable:active {
  color: var(--lighter-black);
  border-bottom-color: transparent;
}

.review-header {
  display: flex;
  flex-grow: 1;
  margin-left: 3rem;
}

.review-header-undo-redo {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}

.review-header-undo-redo button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: 7px;
  height: 100%;
}

.review-header-undo-redo button:hover {
  color: var(--lighter-black);
}

.review-header-undo-redo button:active {
  color: var(--primary);
}

.review-header-undo-redo button:disabled {
  color: var(--lightest-black);
}

.review-header-finish {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: auto;
  margin-bottom: auto;
}

.review-header-finish button {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.review-header-finish svg {
  margin-right: 0.5rem;
}

.rkmodalelement {
  box-shadow: inset 0.5em 0 0 0 var(--primary);

  /* left padding = other padding + left border width */
  padding: 1.5em 1.5em 1.5em 2.2em;
}

.rkmodalelement-underlined {
  border-bottom: 0.05em solid var(--grey);
}

.rkmodalelement-unhovered {
  box-shadow: inset 0.5em 0 0 0 var(--grey);
}

.rkmodalelement-unhovered .rkemphasistext {
  color: var(--grey);
}

.rkmodal.modal-dialog {
  max-width: 45rem !important;
  width: 45rem;
}

.rkmodal .modal-content {
  border: 0;
  border-radius: 0.5em;
  overflow: hidden;
  background-color: var(--lightest-grey);
}

/* Bootstrap overrides */
.modal-backdrop {
  min-height: 100%;
  min-width: 100%;
}

/* Bootstrap enter/exit animation overrides */
.modal.fade {
  transition: opacity 0.4s linear;
}

.modal.fade .rkmodal.modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(-50vw, 0);
          transform: translate(-50vw, 0);
}

.modal.show .rkmodal.modal-dialog {
  -webkit-transform: none;
          transform: none;
}

.savemodal-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-bottom: 0.05em solid var(--grey);
}

.savemodal-form {
  padding: 1em 0 0.5em 0;
}

.savemodal-form input {
  margin: 0.25em 0 1em 0;
}

.savemodal-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.savemodal-button-row .left-margin-button {
  margin-left: 1em;
}

.savemodal-form textarea {
  resize: none;
  height: 38px;
}

.comment-pin-icon-container {
  cursor: pointer;
  font-size: 20px;
}

.comment-pin-icon-enabled {
  color: var(--primary);
}

.comment-pin-icon-disabled {
  color: var(--tertiary);
}

.comment-pin-icon-container-deselected {
  opacity: 0.7;
}

.comment-pin-icon-container-deselected:hover {
  opacity: 1;
}

.comment-pin-icon-backing {
  color: white;
}

.comment-pin-icon-container-selected .comment-pin-icon-backing {
  display: block;
}

.comment-pin-icon-container-selected .comment-pin-icon-backing path {
  stroke: var(--secondary);
  stroke-width: 170px;
}

.pdf-container {
  background: var(--lightest-grey);
  display: flex;
  flex-direction: row;

  /* align-items: center; */

  /* TODO: figure out why center aligning adds vertical space on mobile */
  padding: 1rem;
  height: 100%;
  justify-content: center;
}

.pdf-static-container {
  height: 100%;
}

.pdf-canvas-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.pdf-page-button {
  color: black;
  background-color: transparent;
  border: none;
  box-shadow: none;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.pdf-page-button:disabled {
  color: var(--lightest-black);
  background-color: transparent;
  border: none;
}

.pdf-page-button:focus {
  color: black;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.pdf-page-button:active {
  color: var(--lighter-black) !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.pdf-page-button:hover {
  color: var(--lighter-black);
  background-color: transparent;
  border: none;
}

.pdf-page-back-button {
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 1;
}

.pdf-page-nav-button {
  margin: auto 0.5rem;
}

/* Style to hide a PDF element while still allowing it to fire off events. */
.pdf-hidden {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.canvas-hide {
  visibility: hidden;
}

.skeleton-pdf-container {
  width: 100%;
  height: auto;
}

.skeleton-pdf {
  background-color: white;
  width: 100%;
  height: auto;
}

.zoom-frame {
  overflow: hidden;
  height: 100%;
}

.zoom-item {
  -webkit-transform: scale(1);
          transform: scale(1);
  z-index: -1;
}

.page-flex-container {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.page-fill-height {
  flex: 1 1;
}

.pdf-container-main-body {
  width: 70%;
  min-width: 750px;
  display: inline-block;
}

.pdf-container-side-bar {
  min-width: 160px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 0;
}

.pdf-container-side-bar .rkemphasistext {
  cursor: default;
}

.pdf-container-side-bar-link .rkemphasistext {
  cursor: pointer;
}

.pdf-container-whole-width {
  /* TODO: think about if nowrap should be removed and if the comments bar should move to the bottom for small screens */
  flex-wrap: nowrap;
  height: 100%;
  position: relative;
}

.saved-review-empty-base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.saved-review-empty-resume-block {
  background-color: var(--lighter-grey);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 65vh;
  height: 100%;
  text-align: center;
}

.saved-review-empty-resume-block img {
  max-width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  pointer-events: none;
}

.saved-review-empty-resume-block h5 {
  cursor: default;
  color: var(--lighter-black);
  font-size: 2.5vh;
}

.upload-empty-base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.upload-empty-resume-block {
  background-color: var(--lighter-grey);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 68vh;
  height: 100%;
  text-align: center;
}

.upload-empty-resume-block button {
  width: 11rem;
  height: 3.3rem;
  border-radius: 0.3rem;
}

.upload-empty-resume-block button h5 {
  margin: 0;
}

.upload-empty-base-alert {
  margin: 1rem;
  font-size: small;
  padding: 0.5rem;
}

.upload-empty-resume-block img {
  max-width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  pointer-events: none;
}

.upload-empty-supported-filetype-text {
  color: var(--lightest-black);
}

.delete-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.delete-card-deletebutton {
  padding: 0.75rem 1.5rem;
}

.delete-card-deletebutton-confirming-delete {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}

.delete-card-deletebutton-default {
  margin: 0.5rem;
}

.delete-card-deletebutton h6 {
  margin: 0;
}

/* Color-specific styling for a grey button that turns red on hover */
.delete-card-grey-red-button {
  color: white;
  background-color: var(--tertiary);
  border: none;
}

.delete-card-grey-red-button:enabled:hover {
  background-color: var(--danger-dark);
}

.delete-card-grey-red-button:enabled:focus {
  background-color: var(--danger-dark);
  box-shadow: none;
}

.delete-card-grey-red-button:enabled:active {
  background-color: var(--danger-dark) !important;
  box-shadow: none !important;
}

.delete-card-grey-red-button:disabled {
  color: white;
  background-color: var(--tertiary);
}

.upload-sidebar-resume-name-input .form-control {
  box-shadow: none;
  border-color: var(--grey);
  border-right: none;
}

.upload-sidebar-resume-name-input .form-control:disabled {
  background-color: white;
  color: var(--primary-dark);

  /* Required for Safari */
  -webkit-text-fill-color: var(--primary-dark);
}

.upload-sidebar-resume-name-input button {
  background-color: white;
  color: var(--grey);
  border-color: var(--grey);
  border-left: none;
}

.upload-sidebar-resume-name-input button:hover {
  background-color: white;
  color: var(--lightest-black);
  border-color: var(--grey);
}

.upload-sidebar-resume-name-input button:focus {
  background-color: white;
  color: var(--grey);
  border-color: var(--grey);
  box-shadow: none;
}

.upload-sidebar-resume-name-input button:focus:hover {
  background-color: white;
  color: var(--lightest-black);
  border-color: var(--grey);
}

.upload-sidebar-resume-name-input button:active {
  background-color: white !important;
  color: var(--grey) !important;
  border-color: var(--grey) !important;
  box-shadow: none !important;
  border-left: none !important;
}

.upload-sidebar-resume-name-input button:disabled {
  background-color: white;
  color: var(--light-grey);
  border-color: var(--grey);
}

.upload-sidebar-resume-name-card .rkcontexttext {
  cursor: default;
}

.react-select-creatable-input {
  width: 100%;
  overflow-x: hidden;
}

.react-select__control {
  align-items: baseline !important;
}

.react-select-creatable-input .react-select__value-container {
  align-items: flex-start;
  align-content: flex-start;
  border-color: var(--grey);
  overflow-y: auto;
  resize: none;
}

.react-select-creatable-input .react-select__value-container::-webkit-scrollbar {
  width: 0.5rem;
}

.react-select-creatable-input .react-select__value-container::-webkit-scrollbar-track {
  background: var(--lightest-grey);
}

.react-select-creatable-input .react-select__value-container::-webkit-scrollbar-thumb {
  background: var(--grey);
}

.react-select-creatable-input .react-select__value-container::-webkit-scrollbar-thumb:hover {
  background: var(--lightest-black);
}

.react-select-creatable-input .react-select__placeholder {
  top: auto;
  -webkit-transform: none;
          transform: none;
  padding-top: 0.25rem;
}

.react-select-creatable-input .react-select__indicators {
  align-items: flex-start;
}

.react-select-creatable-input .react-select__multi-value__label {
  background-color: var(--lightest-grey);
}

.react-select-creatable-input .react-select__multi-value__remove {
  background-color: var(--lightest-grey);
}

.react-select-creatable-input .react-select__multi-value__remove:hover {
  background-color: var(--light-grey);
  color: var(--black);
}

.sharemodal-element-body {
  display: flex;
  flex-direction: row;
}

.sharemodal-element-button {
  margin-left: 1em;
  min-width: 6em;
  max-height: 2.375em;
}

.sharemodal-element-body .react-select__value-container {
  height: 5em;
}

.share-card-sharebutton {
  text-align: center;
}

.share-card-sharebutton button {
  margin-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.share-card-sharebutton button h4 {
  margin: 0;
}

.share-card textarea {
  border-color: var(--grey);
  resize: none;
  height: 7rem;
}

.share-card textarea:focus {
  border-color: var(--grey);
  box-shadow: none;
}

.share-card textarea:disabled {
  background-color: white;
}

.upload-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.upload-sidebar-bottom {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.upload-view {
  /* TODO: Revisit this when cards are in */
  min-width: 1044px;
}

