.savemodal-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-bottom: 0.05em solid var(--grey);
}

.savemodal-form {
  padding: 1em 0 0.5em 0;
}

.savemodal-form input {
  margin: 0.25em 0 1em 0;
}

.savemodal-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.savemodal-button-row .left-margin-button {
  margin-left: 1em;
}

.savemodal-form textarea {
  resize: none;
  height: 38px;
}
