.loggedin-dropdown {
  margin-right: 1.2rem;
  margin-top: 5px;
}

.loggedin-dropdown-avatar {
  min-width: 1.8rem;
  max-width: 2.2rem;
  width: 2vw;
  border-radius: 50%;
  margin-right: 0.7rem;
}

.loggedin-dropdown-toggle {
  cursor: pointer;
}

.loggedin-dropdown-toggle:hover {
  color: var(--lighter-black);
}

.loggedin-dropdown-toggle:focus {
  color: var(--lighter-black);
  outline: none;
}

.loggedin-dropdown-toggle:active {
  color: var(--lighter-black);
}

.loggedin-dropdown-menu {
  margin-top: 5px !important;
  border: none !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.loggedin-dropdown-menu .loggedin-dropdown-menu-item:active {
  color: var(--lighter-black);
  background-color: white;
}

.loggedin-dropdown-menu .loggedin-dropdown-menu-item:hover {
  color: var(--lighter-black);
  background-color: white;
}

.loggedin-dropdown-menu .loggedin-dropdown-menu-item:focus {
  color: var(--lighter-black);
  background-color: white;
  outline: none;
}
