/* Vaguely adapted from https://moderncss.dev/pure-css-custom-checkbox-style */

.rkcheckbox-danger {
  color: var(--danger);
}

.rkcheckbox {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 0;
  font-size: 1rem;
}

.rkcheckbox-input-wrapper {
  width: 1.1em;
  height: 1.1em;
  min-width: 1.1em;
  min-height: 1.1em;
  box-shadow: 0 0 0 0.09em inset;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
}

.rkcheckbox-label {
  line-height: 1em;
}

/* Hide the actual input (can't be styled) while maintaining click events */
.rkcheckbox-input-wrapper input {
  visibility: hidden;
  cursor: pointer;
  opacity: 0;
  width: 1em;
  height: 1em;

  /* Controls the touch area of the input box */
  position: absolute;
  left: 0;
  top: 0;
}

.rkcheckbox-checked-icon {
  pointer-events: none;
  width: 0.6em;
  height: 0.6em;
  border-radius: 0.15em;
  background-color: currentColor;
  transition: opacity 0.15s;
  opacity: 0;
}

.rkcheckbox-input-wrapper input:hover + .rkcheckbox-checked-icon {
  opacity: 0.25;
}

.rkcheckbox-input-wrapper input:checked + .rkcheckbox-checked-icon {
  opacity: 1;
}
