.rkhelptooltip .tooltip {
  opacity: 1;
}

.rkhelptooltip {
  transition: opacity 0.1s linear;
}

.rkhelptooltip .tooltip-inner {
  background-color: var(--secondary);
  border-radius: 3;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}

.rkhelptooltip .arrow::before {
  border-bottom-color: var(--secondary) !important;
}

@media (hover: none) {
  /*  Stop tooltip from showing on devices without hover (ex: tables)
   *  https://stackoverflow.com/a/54556732
   */
  .rkhelptooltip .tooltip {
    display: none;
  }
}
