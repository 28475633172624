.react-select-creatable-input {
  width: 100%;
  overflow-x: hidden;
}

.react-select__control {
  align-items: baseline !important;
}

.react-select-creatable-input .react-select__value-container {
  align-items: flex-start;
  align-content: flex-start;
  border-color: var(--grey);
  overflow-y: auto;
  resize: none;
}

.react-select-creatable-input .react-select__value-container::-webkit-scrollbar {
  width: 0.5rem;
}

.react-select-creatable-input .react-select__value-container::-webkit-scrollbar-track {
  background: var(--lightest-grey);
}

.react-select-creatable-input .react-select__value-container::-webkit-scrollbar-thumb {
  background: var(--grey);
}

.react-select-creatable-input .react-select__value-container::-webkit-scrollbar-thumb:hover {
  background: var(--lightest-black);
}

.react-select-creatable-input .react-select__placeholder {
  top: auto;
  transform: none;
  padding-top: 0.25rem;
}

.react-select-creatable-input .react-select__indicators {
  align-items: flex-start;
}

.react-select-creatable-input .react-select__multi-value__label {
  background-color: var(--lightest-grey);
}

.react-select-creatable-input .react-select__multi-value__remove {
  background-color: var(--lightest-grey);
}

.react-select-creatable-input .react-select__multi-value__remove:hover {
  background-color: var(--light-grey);
  color: var(--black);
}
