.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.canvas-hide {
  visibility: hidden;
}
