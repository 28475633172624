/* sm */
.homeview {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 274px;
}

.homeview .header-title h1 {
  display: none;
}

.homeview .login-loggedin-avatar {
  width: 2.5rem;
}

.homeview .login-loggedin-name {
  display: none;
}

.homeview .login-loggedin {
  margin-right: 0;
  padding-right: 0;
}

.homeview-content {
  overflow-y: scroll;
  padding-bottom: 30px;
}

/* md */
@media screen and (min-width: 768px) {
  .homeview .header-title h1 {
    display: inline;
  }

  .homeview .login-loggedin-avatar {
    width: 2.5rem;
  }

  .homeview .login-loggedin-name {
    display: inline;
  }

  .homeview .login-loggedin {
    margin-right: 1.2rem;
    padding-right: 0.6rem;
  }

  .homeview-content {
    padding-bottom: 40px;
  }
}

/* lg */
@media screen and (min-width: 992px) {
  .pdftoolbar .tool-button-text {
    display: inline;
    font-size: 1.2vw;
  }
}

/* xl */
@media screen and (min-width: 1200px) {
  .pdftoolbar .tool-button-text {
    font-size: 1vw;
  }
}
