.upload-empty-base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.upload-empty-resume-block {
  background-color: var(--lighter-grey);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 68vh;
  height: 100%;
  text-align: center;
}

.upload-empty-resume-block button {
  width: 11rem;
  height: 3.3rem;
  border-radius: 0.3rem;
}

.upload-empty-resume-block button h5 {
  margin: 0;
}

.upload-empty-base-alert {
  margin: 1rem;
  font-size: small;
  padding: 0.5rem;
}

.upload-empty-resume-block img {
  max-width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  pointer-events: none;
}

.upload-empty-supported-filetype-text {
  color: var(--lightest-black);
}
