@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,500,700');

:root {
  --primary: #01d2b7; /* Primary accent colour */
  --primary-dark: #1abba6; /* Slightly darker primary, for stuff like hover */
  --secondary: #07383b; /* Secondary accent colour, for things like buttons */
  --secondary-light: #2b656d; /* Slightly lighter secondary, for stuff like hover */
  --tertiary: #acacac; /* Tertiary accent colour, for things like buttons */
  --tertiary-dark: #9b9b9b; /* Slightly darker tertiary, for stuff like hover */
  --danger: #ff7171; /* Danger colour */
  --danger-dark: #ec5252; /* Slightly darker danger, for stuff like hover */
  --lighter-black: #6d6d6d; /* A lighter black colour, for stuff like hover on black text */
  --lightest-black: #737373; /* Lightest black colour, for stuff like disabled black text */
  --grey: #acacac; /* For outlines of white elements */
  --light-grey: #e3e3e3; /* For placeholder element hover colours */
  --lighter-grey: #f0f0f0; /* For placeholder element colours */
  --lightest-grey: #f6f6f6; /* Grey for backgrounds, neutral alternate to white */
}

h1 {
  font-family: 'Roboto', sans-serif;
}

body {
  font-family: 'Open Sans', sans-serif;
  height: 100vh;
  background-color: var(--lightest-grey);
}

body .tooltip {
  font-family: 'Open Sans', sans-serif;
}

/* ----- Customize scrollbar ------ */

/* Only supported in Chrome, Safari, and Edge */
*::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 8px;
}

*::-webkit-scrollbar-button {
  display: none;
}

/* --------------------------------- */

/* ----- Customize Cursors ------ */
.pin-cursor {
  /* Only works on Safari and Chrome */
  cursor: -webkit-image-set(url("../assets/pin-cursor.svg") 1x, url("../assets/pin-cursor2.svg") 2x) 8 19, auto;
}

@-moz-document url-prefix() {
  .pin-cursor {
    cursor: url('../assets/pin-cursor.svg') 8 19, auto;
  }
}

.draw-cursor {
  /* Only works on Safari and Chrome */
  cursor: -webkit-image-set(url("../assets/draw-cursor.svg") 1x, url("../assets/draw-cursor2.svg") 2x) 2 18, auto;
}

@-moz-document url-prefix() {
  .draw-cursor {
    cursor: url('../assets/draw-cursor.svg') 0 18, auto;
  }
}

.erase-cursor {
  /* Only works on Safari and Chrome */
  cursor: -webkit-image-set(url("../assets/erase-cursor.svg") 1x, url("../assets/erase-cursor2.svg") 2x) 5 16, auto;
}

@-moz-document url-prefix() {
  .erase-cursor {
    cursor: url('../assets/erase-cursor.svg') 5 16, auto;
  }
}

.crosshair-cursor {
  cursor: crosshair;
}

.no-pointer-events {
  pointer-events: none;
}

/* --------------------------------- */

/* dark green (primary coloured) button */
.primary-button {
  color: white;
  background-color: var(--primary);
  border: none;
}

.primary-button:hover {
  background-color: var(--primary-dark);
}

.primary-button:focus {
  background-color: var(--primary-dark);
  box-shadow: none;
}

.primary-button:active {
  background-color: var(--primary-dark) !important;
  box-shadow: none !important;
}

.primary-button:disabled {
  background-color: var(--primary);
}

.primary-button:disabled:active {
  background-color: var(--primary) !important;
}

/* grey button */
.tertiary-button {
  color: white;
  background-color: var(--tertiary);
  border: none;
}

.tertiary-button:hover {
  color: white;
  background-color: var(--tertiary-dark);
}

.tertiary-button:focus {
  color: white;
  background-color: var(--tertiary-dark);
  box-shadow: none;
}

.tertiary-button:active {
  color: white !important;
  background-color: var(--tertiary-dark) !important;
  box-shadow: none !important;
}

.tertiary-button:disabled {
  color: white;
  background-color: var(--tertiary);
}

.tertiary-button:disabled:active {
  color: white !important;
  background-color: var(--tertiary);
}

/* danger (red coloured) button */
.danger-button {
  color: white;
  background-color: var(--danger);
  border: none;
}

.danger-button:hover {
  background-color: var(--danger-dark);
}

.danger-button:focus {
  background-color: var(--danger-dark);
  box-shadow: none;
}

.danger-button:active {
  background-color: var(--danger-dark) !important;
  box-shadow: none !important;
}

.danger-button:disabled {
  background-color: var(--danger);
}

.danger-button:disabled:active {
  background-color: var(--danger) !important;
}

/* tool buttons */
.tool-button {
  background-color: transparent;
  color: black;
  height: 2.8rem;
  border-radius: 0;
  padding-top: 0;
  border: none;
  padding-bottom: 0.75rem !important;
}

.tool-button-selected {
  color: var(--primary-dark);
  height: 2.8rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.3rem solid var(--primary);
  padding-bottom: 0.45rem !important;
}

.tool-button:hover {
  background-color: transparent;
  color: var(--lighter-black);
  border: none;
  padding-bottom: 0.75rem !important;
}

.tool-button:active {
  background-color: transparent !important;
  color: var(--lighter-black) !important;
  border: none !important;
  box-shadow: none !important;
  padding-bottom: 0.75rem !important;
}

.tool-button:focus {
  background-color: transparent;
  box-shadow: none;
  color: var(--lighter-black);
  height: 2.8rem;
  padding-bottom: 0.75rem !important;
}

.tool-button:disabled {
  background-color: transparent;
  color: var(--lightest-black);
  height: 2.8rem;
  border: none;
  padding-bottom: 0.75rem !important;
}

.tool-button-selected:hover {
  color: var(--primary-dark) !important;
  height: 2.8rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.3rem solid var(--primary) !important;
  padding-bottom: 0.45rem !important;
}

.tool-button-selected:active {
  color: var(--primary-dark);
  height: 2.8rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.3rem solid var(--primary);
  padding-bottom: 0.45rem !important;
}

.tool-button-selected:focus {
  color: var(--primary-dark);
  height: 2.8rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.3rem solid var(--primary);
  padding-bottom: 0.45rem !important;
}

.tool-button-selected:disabled {
  background-color: transparent;
  color: var(--lightest-black);
  height: 2.8rem;
  border: none;
  padding-bottom: 0.75rem !important;
}

.tool-button-selected:disabled:hover {
  background-color: transparent !important;
  color: var(--lightest-black) !important;
  border: none !important;
  padding-bottom: 0.75rem !important;
}
