.recent-uploads-container {
  margin-top: 20px;
}

.recent-uploads-header-title {
  flex-grow: 1;
  color: var(--lighter-black);
}

/* sm */
.recent-uploads-header-small {
  display: block;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 30px;
}

.recent-uploads-header-wide {
  display: none;
}

/* md */
@media screen and (min-width: 768px) {
  .recent-uploads-header-small {
    display: none;
  }

  .recent-uploads-header-wide {
    display: flex;
    align-items: center;
    margin: 0 0.35rem;
    height: 35px;
  }
}

.upload-outline {
  border-radius: 5px;
  outline: 4px solid var(--secondary-light);
}
