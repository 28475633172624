.scroll-scrollable {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow-y: auto;
}

.scroll-content {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
}

.scroll-overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  z-index: 1;
  top: 0;
  pointer-events: none;
}

.scroll-overlay::before,
.scroll-overlay::after {
  content: '';
  height: 40px;
  position: absolute;
}

.scroll-overlay::before {
  background: linear-gradient(white, rgba(255, 255, 255, 0));
  top: 0;
  width: var(--widthTop);
}

.scroll-overlay::after {
  background: linear-gradient(rgba(255, 255, 255, 0), white);
  bottom: 0;
  width: var(--widthBottom);
}
