.pdf-container {
  background: var(--lightest-grey);
  display: flex;
  flex-direction: row;

  /* align-items: center; */

  /* TODO: figure out why center aligning adds vertical space on mobile */
  padding: 1rem;
  height: 100%;
  justify-content: center;
}

.pdf-static-container {
  height: 100%;
}

.pdf-canvas-wrapper {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.pdf-page-button {
  color: black;
  background-color: transparent;
  border: none;
  box-shadow: none;
  height: fit-content;
}

.pdf-page-button:disabled {
  color: var(--lightest-black);
  background-color: transparent;
  border: none;
}

.pdf-page-button:focus {
  color: black;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.pdf-page-button:active {
  color: var(--lighter-black) !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.pdf-page-button:hover {
  color: var(--lighter-black);
  background-color: transparent;
  border: none;
}

.pdf-page-back-button {
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 1;
}

.pdf-page-nav-button {
  margin: auto 0.5rem;
}

/* Style to hide a PDF element while still allowing it to fire off events. */
.pdf-hidden {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
