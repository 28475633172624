.login-container {
  align-items: center;
  margin-top: 1.5rem;
  width: 100%;
}

.login-container button {
  justify-content: center;
  font-size: 1.1rem !important;
  margin-bottom: 0.5rem;
  width: 280px;
  height: 60px;
  border-radius: 7px !important;
  border: 1px solid lightgrey !important;
  box-shadow: rgb(0 0 0 / 24%) 0 2px 2px 0, rgb(0 0 0 / 24%) 0 0 1px 0;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.54);
}

.login-container .alert {
  margin-bottom: 0.7rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 40rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.login-container button:hover {
  opacity: 0.9;
}
