.review-header {
  display: flex;
  flex-grow: 1;
  margin-left: 3rem;
}

.review-header-undo-redo {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}

.review-header-undo-redo button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: 7px;
  height: 100%;
}

.review-header-undo-redo button:hover {
  color: var(--lighter-black);
}

.review-header-undo-redo button:active {
  color: var(--primary);
}

.review-header-undo-redo button:disabled {
  color: var(--lightest-black);
}

.review-header-finish {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: auto;
  margin-bottom: auto;
}

.review-header-finish button {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.review-header-finish svg {
  margin-right: 0.5rem;
}
