@keyframes error-toast-entering {
  from {
    bottom: -4rem;
  }

  to {
    bottom: 1rem;
  }
}

@keyframes error-toast-exiting {
  from {
    bottom: 1rem;
  }

  to {
    bottom: -4rem;
  }
}

.error-toast-icon-wrapper {
  margin-right: 1em;
  border: 0.25em solid white;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-toast {
  background-color: var(--danger);
  color: white;
  padding: 1em;
  border-radius: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  /* Position-related */
  position: fixed;
  right: 1rem;
  z-index: 100;
}

.error-toast-entering {
  bottom: 1rem;
  animation-name: error-toast-entering;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.38, 1.4, 0.64, 1);
}

.error-toast-exiting {
  bottom: -4rem;
  animation-name: error-toast-exiting;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
}

.error-toast-hidden {
  display: none;
}
