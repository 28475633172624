.delete-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.delete-card-deletebutton {
  padding: 0.75rem 1.5rem;
}

.delete-card-deletebutton-confirming-delete {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}

.delete-card-deletebutton-default {
  margin: 0.5rem;
}

.delete-card-deletebutton h6 {
  margin: 0;
}

/* Color-specific styling for a grey button that turns red on hover */
.delete-card-grey-red-button {
  color: white;
  background-color: var(--tertiary);
  border: none;
}

.delete-card-grey-red-button:enabled:hover {
  background-color: var(--danger-dark);
}

.delete-card-grey-red-button:enabled:focus {
  background-color: var(--danger-dark);
  box-shadow: none;
}

.delete-card-grey-red-button:enabled:active {
  background-color: var(--danger-dark) !important;
  box-shadow: none !important;
}

.delete-card-grey-red-button:disabled {
  color: white;
  background-color: var(--tertiary);
}
