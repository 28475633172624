.skeleton-pdf-container {
  width: 100%;
  height: auto;
}

.skeleton-pdf {
  background-color: white;
  width: 100%;
  height: auto;
}
