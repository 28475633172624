.rkpill {
  width: fit-content;
  z-index: 1;
  margin: 10px;
  border-radius: 50px;
  padding: 0 10px;
  pointer-events: none;

  /* Default colours */
  color: white;
  background-color: var(--primary);
}

.rkpill-danger {
  color: white;
  background-color: var(--danger);
}

.rkpill-topright {
  position: absolute;
  top: 0;
  right: 0;
}
