.upload-sidebar-resume-name-input .form-control {
  box-shadow: none;
  border-color: var(--grey);
  border-right: none;
}

.upload-sidebar-resume-name-input .form-control:disabled {
  background-color: white;
  color: var(--primary-dark);

  /* Required for Safari */
  -webkit-text-fill-color: var(--primary-dark);
}

.upload-sidebar-resume-name-input button {
  background-color: white;
  color: var(--grey);
  border-color: var(--grey);
  border-left: none;
}

.upload-sidebar-resume-name-input button:hover {
  background-color: white;
  color: var(--lightest-black);
  border-color: var(--grey);
}

.upload-sidebar-resume-name-input button:focus {
  background-color: white;
  color: var(--grey);
  border-color: var(--grey);
  box-shadow: none;
}

.upload-sidebar-resume-name-input button:focus:hover {
  background-color: white;
  color: var(--lightest-black);
  border-color: var(--grey);
}

.upload-sidebar-resume-name-input button:active {
  background-color: white !important;
  color: var(--grey) !important;
  border-color: var(--grey) !important;
  box-shadow: none !important;
  border-left: none !important;
}

.upload-sidebar-resume-name-input button:disabled {
  background-color: white;
  color: var(--light-grey);
  border-color: var(--grey);
}

.upload-sidebar-resume-name-card .rkcontexttext {
  cursor: default;
}
