.rknavbutton {
  padding: 5px 10px;
  border-radius: 5px;
}

.rknavbutton-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.rknavbutton-content h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.rknavbutton-left svg {
  margin-right: 6px;
}

.rknavbutton-right svg {
  margin-left: 6px;
}
