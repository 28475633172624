.pdf-container-main-body {
  width: 70%;
  min-width: 750px;
  display: inline-block;
}

.pdf-container-side-bar {
  min-width: 160px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 0;
}

.pdf-container-side-bar .rkemphasistext {
  cursor: default;
}

.pdf-container-side-bar-link .rkemphasistext {
  cursor: pointer;
}

.pdf-container-whole-width {
  /* TODO: think about if nowrap should be removed and if the comments bar should move to the bottom for small screens */
  flex-wrap: nowrap;
  height: 100%;
  position: relative;
}
