.delete-popover button {
  display: flex;
  flex-grow: 1;
  margin-left: auto;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.delete-popover .popover {
  border-radius: 5px;
  border: 2px solid var(--danger);
  max-width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 8px;
}

.delete-popover .arrow::before {
  border-bottom-color: var(--danger) !important;
  border-top-color: var(--danger) !important;
}

.delete-popover .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 3px;
}

.delete-popover .bs-popover-auto[x-placement^="top"] > .arrow::after {
  top: -3px;
}

.delete-popover .rkcontexttext {
  cursor: default;
}
