.login-page {
  background-image: url("../assets/login-bg.png");
  height: 100vh;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-width: 500px;
}

.login-page .navbar {
  background-color: transparent;
}

.login-page-modal-body {
  justify-content: center;
  text-align: center;
  object-fit: contain;
}

/* sm */
.login-page-modal {
  background-color: rgba(250, 250, 250, 0.85);
  width: 80%;
  border-radius: 9px;
  border-color: #f1f1f1;
  margin: auto;
  padding-top: 70px;
  padding-bottom: 100px;
  padding-left: 60px;
  padding-right: 60px;
}

.login-page-modal img {
  display: none;
}

.login-page-modal h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.login-page-modal h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  margin-bottom: 0.7rem;
}

/* md */
@media screen and (min-width: 768px) {
  .login-page-modal {
    width: 740px;
    height: 75vh;
    padding-top: 30px;
    padding-bottom: 0;
    padding-left: 60px;
    padding-right: 60px;
  }

  .login-page-modal img {
    display: inline-block;
    height: 40vh;
  }

  .login-page-modal h3 {
    margin-bottom: 2rem;
  }
}

/* lg */
@media screen and (min-width: 992px) {
  .login-page-modal {
    width: 770px;
    height: 80vh;
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 60px;
  }

  .login-page-modal img {
    height: 45vh;
  }

  .login-page-modal h3 {
    margin-bottom: 2.5rem;
  }
}
