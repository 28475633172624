.rkcard {
  background-color: white;
  border-radius: 5px;
  box-shadow: -4px 4px 4px 0 rgba(0, 0, 0, 0.1);
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 23px;
  padding-right: 23px;
}

.rkcard-default-margins {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
}

.rkcard-no-top-margins {
  margin-bottom: 10px;
  margin-right: 5px;
}

.rkcard-center {
  text-align: center;
}
