.last-comments {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%; /* Necessary for Safari to display comments */
}

.last-comments-header {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 1.4em;
  font-weight: 500;
}

.last-comments-header-disabled {
  color: var(--lightest-black);
}

.commentmodal-button {
  color: var(--tertiary);
  position: absolute;
  top: 0.2em;
  right: 0.3em;
}

.commentmodal-button:hover {
  color: var(--tertiary-dark);
}

.last-comments-comment-block-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.4em 0.2em 0.3em;
}

.last-comments-pin {
  font-size: 1.65em;
  color: var(--primary);
  top: -0.38em;
  left: -0.28em;
  position: absolute;
}

.last-comments-pin-enabled {
  color: var(--primary);
}

.last-comments-pin-disabled {
  color: var(--tertiary);
}

.last-comments-pin-deselected {
  opacity: 0.7;
}

.last-comments-pin-backing {
  color: white;
}

.last-comments-pin-comment {
  margin-bottom: 0.5em;
  flex-wrap: nowrap;
  cursor: pointer;
}

.last-comments-pin-outline path {
  stroke: var(--secondary);
  stroke-width: 120px;
}

.last-comments-pin-comment:hover .last-comments-pin-deselected {
  opacity: 1;
}

.last-comments-footer-text {
  margin-left: 0.3rem;
}

.last-comments-comment {
  text-align: left;
  font-size: 0.9em;
  padding: 0.5em 1em 0 1em;
}

.last-comments-comment-collapse {
  padding: 0 1em 0 1em;
  max-height: 2.5em;
}

.last-comments-comment-dismissed {
  overflow: hidden;
}

.last-comments-comment-dismissed .comment-box-input:disabled {
  color: var(--tertiary-dark);
}

.last-comments-comment-block {
  background-color: white;
  flex-grow: 1;
  flex-direction: row;
  margin-top: 0.4em;
  margin-left: 0.4rem;
  border: 1px solid var(--grey);
  border-radius: 0.3em;
  box-shadow: 2px 4px 4px rgba(7, 57, 60, 0.25);
  position: relative;
}
