.notes-displayer .form-control:disabled {
  height: 4rem;
  box-shadow: none;
  border-color: var(--grey);
  font-size: small;
  resize: none;
  background-color: white;

  /* Required for Safari */
  -webkit-text-fill-color: black;
}

.notes-text-displayer {
  margin: 0;
  padding: 0.375rem 0.75rem;
  flex-grow: 1;
  resize: none;
  width: 100%;
  border-color: var(--grey);
  overflow: hidden;
  background-color: white;
  font-size: small;
  overflow-y: scroll;

  /* Rounded corners */
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
