.all-resumes-container {
  margin-top: 20px;
}

.all-resumes-header-title {
  color: var(--lighter-black);
  margin-left: 20px;
}

/* sm */
.all-resumes-header {
  display: flex;
  align-items: flex-end;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

/* md */
@media screen and (min-width: 768px) {
  .all-resumes-header {
    margin: 0 0.35rem;
  }
}
