.saved-review-empty-base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.saved-review-empty-resume-block {
  background-color: var(--lighter-grey);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 65vh;
  height: 100%;
  text-align: center;
}

.saved-review-empty-resume-block img {
  max-width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  pointer-events: none;
}

.saved-review-empty-resume-block h5 {
  cursor: default;
  color: var(--lighter-black);
  font-size: 2.5vh;
}
