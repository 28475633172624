.notfound {
  margin: 2rem 2.5rem 2rem 2.5rem;
}

.notfound button {
  margin: 0.5rem 0 0.5rem 0;
  background-color: var(--primary);
  border-color: transparent;
  color: white;
}

.notfound button:hover {
  background-color: var(--primary-dark);
  border-color: transparent;
  color: white;
}

.notfound button:focus {
  background-color: var(--primary-dark);
  border-color: transparent;
  color: white;
  box-shadow: none;
}

.notfound button:active {
  background-color: var(--primary) !important;
  border-color: transparent !important;
  color: white !important;
  box-shadow: none !important;
}
