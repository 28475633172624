.share-card-sharebutton {
  text-align: center;
}

.share-card-sharebutton button {
  margin-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.share-card-sharebutton button h4 {
  margin: 0;
}

.share-card textarea {
  border-color: var(--grey);
  resize: none;
  height: 7rem;
}

.share-card textarea:focus {
  border-color: var(--grey);
  box-shadow: none;
}

.share-card textarea:disabled {
  background-color: white;
}
