.comment-pin-icon-container {
  cursor: pointer;
  font-size: 20px;
}

.comment-pin-icon-enabled {
  color: var(--primary);
}

.comment-pin-icon-disabled {
  color: var(--tertiary);
}

.comment-pin-icon-container-deselected {
  opacity: 0.7;
}

.comment-pin-icon-container-deselected:hover {
  opacity: 1;
}

.comment-pin-icon-backing {
  color: white;
}

.comment-pin-icon-container-selected .comment-pin-icon-backing {
  display: block;
}

.comment-pin-icon-container-selected .comment-pin-icon-backing path {
  stroke: var(--secondary);
  stroke-width: 170px;
}
