.header-fill {
  flex-grow: 1;
}

.header-shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.header-title h1 {
  margin: 0;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2rem !important;
  color: black;
  margin-left: 0.5rem;
}

.header-logo {
  height: 100%;
  max-height: 30px;
  vertical-align: baseline;
  display: inline;
}

.header-user-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
}

/* sm */
.header {
  display: flex;
  padding-left: 0.6rem;
  width: 100%;
}

.header .navbar-brand {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}

.header-wrapper .navbar {
  background-color: white;
  padding-top: 0;
  padding-bottom: 0 !important;
  align-items: start !important;
}

/* md */
@media screen and (min-width: 768px) {
  .header {
    min-height: 44px;
    max-height: 53px;
    height: 3vw;
  }

  .header .navbar-brand {
    align-items: unset;
  }
}
