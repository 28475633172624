.sharemodal-element-body {
  display: flex;
  flex-direction: row;
}

.sharemodal-element-button {
  margin-left: 1em;
  min-width: 6em;
  max-height: 2.375em;
}

.sharemodal-element-body .react-select__value-container {
  height: 5em;
}
