.rkheaderbutton {
  padding: 0.4rem 0.8rem;
  background-color: transparent;
  border: 0;

  /* This line fixes flickering issues with the bottom border on transition, but I have no idea why */
  border-top: 0.1px solid transparent;
  border-bottom: 5px solid transparent;
  transition: color 0.15s, border-bottom-color 0.1s;
}

.rkheaderbutton:disabled {
  pointer-events: none;
  color: var(--lightest-black);
  border-bottom-color: transparent;
}

.rkheaderbutton-deselected {
  color: black;
  border-bottom-color: transparent;
}

.rkheaderbutton-deselected:hover {
  color: var(--lighter-black);
  border-bottom-color: transparent;
}

.rkheaderbutton-deselected:active {
  color: var(--primary);
  border-bottom-color: var(--primary);
}

.rkheaderbutton-selected {
  color: var(--primary);
  border-bottom-color: var(--primary);
}

.rkheaderbutton-selected-deselectable {
  color: var(--primary);
  border-bottom-color: var(--primary);
}

.rkheaderbutton-selected-deselectable:active {
  color: var(--lighter-black);
  border-bottom-color: transparent;
}
