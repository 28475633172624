.comment-box-input {
  margin: 0;
  padding: 0.3rem 0.3rem 0.1rem 0.3rem !important;
  border: none !important;
  flex-grow: 1;
  resize: none;
  width: 100%;
  outline: none;
  overflow: hidden;
}

.comment-box-input textarea {
  overflow: hidden;
}

.comment-box-input:focus {
  box-shadow: none !important;
}

.comment-box-input:disabled {
  background-color: white;
  cursor: pointer;
  color: black;
}
