.rkmodalelement {
  box-shadow: inset 0.5em 0 0 0 var(--primary);

  /* left padding = other padding + left border width */
  padding: 1.5em 1.5em 1.5em 2.2em;
}

.rkmodalelement-underlined {
  border-bottom: 0.05em solid var(--grey);
}

.rkmodalelement-unhovered {
  box-shadow: inset 0.5em 0 0 0 var(--grey);
}

.rkmodalelement-unhovered .rkemphasistext {
  color: var(--grey);
}
